/* vollkorn-regular - latin */
@font-face {
    font-family: 'Vollkorn';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/vollkorn/vollkorn-v21-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/vollkorn/vollkorn-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/vollkorn/vollkorn-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/vollkorn/vollkorn-v21-latin-regular.svg#Vollkorn') format('svg'); /* Legacy iOS */
  }
  /* vollkorn-500 - latin */
  @font-face {
    font-family: 'Vollkorn';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/vollkorn/vollkorn-v21-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/vollkorn/vollkorn-v21-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/vollkorn/vollkorn-v21-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/vollkorn/vollkorn-v21-latin-500.svg#Vollkorn') format('svg'); /* Legacy iOS */
  }
  /* vollkorn-600 - latin */
  @font-face {
    font-family: 'Vollkorn';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/vollkorn/vollkorn-v21-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/vollkorn/vollkorn-v21-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/vollkorn/vollkorn-v21-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/vollkorn/vollkorn-v21-latin-600.svg#Vollkorn') format('svg'); /* Legacy iOS */
  }
  /* vollkorn-700 - latin */
  @font-face {
    font-family: 'Vollkorn';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/vollkorn/vollkorn-v21-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/vollkorn/vollkorn-v21-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/vollkorn/vollkorn-v21-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/vollkorn/vollkorn-v21-latin-700.svg#Vollkorn') format('svg'); /* Legacy iOS */
  }
  /* vollkorn-800 - latin */
  @font-face {
    font-family: 'Vollkorn';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/vollkorn/vollkorn-v21-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/vollkorn/vollkorn-v21-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/vollkorn/vollkorn-v21-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-800.woff') format('woff'), /* Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/vollkorn/vollkorn-v21-latin-800.svg#Vollkorn') format('svg'); /* Legacy iOS */
  }
  /* vollkorn-900 - latin */
  @font-face {
    font-family: 'Vollkorn';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/vollkorn/vollkorn-v21-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/vollkorn/vollkorn-v21-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/vollkorn/vollkorn-v21-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-900.woff') format('woff'), /* Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/vollkorn/vollkorn-v21-latin-900.svg#Vollkorn') format('svg'); /* Legacy iOS */
  }
  /* vollkorn-italic - latin */
  @font-face {
    font-family: 'Vollkorn';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/vollkorn/vollkorn-v21-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/vollkorn/vollkorn-v21-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/vollkorn/vollkorn-v21-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/vollkorn/vollkorn-v21-latin-italic.svg#Vollkorn') format('svg'); /* Legacy iOS */
  }
  /* vollkorn-500italic - latin */
  @font-face {
    font-family: 'Vollkorn';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/vollkorn/vollkorn-v21-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/vollkorn/vollkorn-v21-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/vollkorn/vollkorn-v21-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-500italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/vollkorn/vollkorn-v21-latin-500italic.svg#Vollkorn') format('svg'); /* Legacy iOS */
  }
  /* vollkorn-600italic - latin */
  @font-face {
    font-family: 'Vollkorn';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/vollkorn/vollkorn-v21-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/vollkorn/vollkorn-v21-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/vollkorn/vollkorn-v21-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-600italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/vollkorn/vollkorn-v21-latin-600italic.svg#Vollkorn') format('svg'); /* Legacy iOS */
  }
  /* vollkorn-700italic - latin */
  @font-face {
    font-family: 'Vollkorn';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/vollkorn/vollkorn-v21-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/vollkorn/vollkorn-v21-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/vollkorn/vollkorn-v21-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/vollkorn/vollkorn-v21-latin-700italic.svg#Vollkorn') format('svg'); /* Legacy iOS */
  }
  /* vollkorn-800italic - latin */
  @font-face {
    font-family: 'Vollkorn';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/vollkorn/vollkorn-v21-latin-800italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/vollkorn/vollkorn-v21-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/vollkorn/vollkorn-v21-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-800italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/vollkorn/vollkorn-v21-latin-800italic.svg#Vollkorn') format('svg'); /* Legacy iOS */
  }
  /* vollkorn-900italic - latin */
  @font-face {
    font-family: 'Vollkorn';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/vollkorn/vollkorn-v21-latin-900italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/vollkorn/vollkorn-v21-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/vollkorn/vollkorn-v21-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-900italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/vollkorn/vollkorn-v21-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/vollkorn/vollkorn-v21-latin-900italic.svg#Vollkorn') format('svg'); /* Legacy iOS */
  }







  /* LATO */

  /* lato-regular - latin */
@font-face {
     font-family: 'Lato';
     font-style: normal;
     font-weight: 400;
    font-display: swap;
    src: url('../fonts/lato/lato-v23-latin-regular.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../fonts/lato/lato-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/lato/lato-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/lato/lato-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
          url('../fonts/lato/lato-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/lato/lato-v23-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
   }
   /* lato-italic - latin */
   @font-face {
     font-family: 'Lato';
     font-style: italic;
     font-weight: 400;
    font-display: swap;
    src: url('../fonts/lato/lato-v23-latin-italic.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../fonts/lato/lato-v23-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/lato/lato-v23-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/lato/lato-v23-latin-italic.woff') format('woff'), /* Modern Browsers */
          url('../fonts/lato/lato-v23-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/lato/lato-v23-latin-italic.svg#Lato') format('svg'); /* Legacy iOS */
   }
   /* lato-700 - latin */
   @font-face {
     font-family: 'Lato';
     font-style: normal;
     font-weight: 700;
    font-display: swap;
    src: url('../fonts/lato/lato-v23-latin-700.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../fonts/lato/lato-v23-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/lato/lato-v23-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/lato/lato-v23-latin-700.woff') format('woff'), /* Modern Browsers */
          url('../fonts/lato/lato-v23-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/lato/lato-v23-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
   }
   /* lato-700italic - latin */
   @font-face {
     font-family: 'Lato';
     font-style: italic;
     font-weight: 700;
    font-display: swap;
    src: url('../fonts/lato/lato-v23-latin-700italic.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../fonts/lato/lato-v23-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/lato/lato-v23-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/lato/lato-v23-latin-700italic.woff') format('woff'), /* Modern Browsers */
          url('../fonts/lato/lato-v23-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/lato/lato-v23-latin-700italic.svg#Lato') format('svg'); /* Legacy iOS */
   }
   /* lato-900 - latin */
   @font-face {
     font-family: 'Lato';
     font-style: normal;
     font-weight: 900;
    font-display: swap;
    src: url('../fonts/lato/lato-v23-latin-900.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../fonts/lato/lato-v23-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/lato/lato-v23-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/lato/lato-v23-latin-900.woff') format('woff'), /* Modern Browsers */
          url('../fonts/lato/lato-v23-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/lato/lato-v23-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
   }
   /* lato-900italic - latin */
   @font-face {
     font-family: 'Lato';
     font-style: italic;
     font-weight: 900;
    font-display: swap;
    src: url('../fonts/lato/lato-v23-latin-900italic.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../fonts/lato/lato-v23-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/lato/lato-v23-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/lato/lato-v23-latin-900italic.woff') format('woff'), /* Modern Browsers */
          url('../fonts/lato/lato-v23-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/lato/lato-v23-latin-900italic.svg#Lato') format('svg'); /* Legacy iOS */
   }



/* ALTONA */
@font-face {
     font-family: 'Altona Sans';
    font-display: swap;
    src: url('../fonts/altona/AltonaSans-Regular.eot');
     src: url('../fonts/altona/AltonaSans-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/altona/AltonaSans-Regular.woff2') format('woff2'),
         url('../fonts/altona/AltonaSans-Regular.woff') format('woff'),
         url('../fonts/altona/AltonaSans-Regular.ttf') format('truetype'),
         url('../fonts/altona/AltonaSans-Regular.svg#AltonaSans-Regular') format('svg');
     font-weight: normal;
     font-style: normal;
     font-display: swap;
 }
 
 @font-face {
     font-family: 'Altona Sans';
    font-display: swap;
    src: url('../fonts/altona/AltonaSans-Italic.eot');
     src: url('../fonts/altona/AltonaSans-Italic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/altona/AltonaSans-Italic.woff2') format('woff2'),
         url('../fonts/altona/AltonaSans-Italic.woff') format('woff'),
         url('../fonts/altona/AltonaSans-Italic.ttf') format('truetype'),
         url('../fonts/altona/AltonaSans-Italic.svg#AltonaSans-Italic') format('svg');
     font-weight: 500;
     font-style: italic;
     font-display: swap;
 }






 /* MONTSERRAT */
 /* montserrat-regular - latin */
@font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 400;
    font-display: swap;
    src: url('../fonts/montserrat/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../fonts/montserrat/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/montserrat/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/montserrat/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
          url('../fonts/montserrat/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/montserrat/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
   }
   /* montserrat-500 - latin */
   @font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 500;
    font-display: swap;
    src: url('../fonts/montserrat/montserrat-v25-latin-500.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../fonts/montserrat/montserrat-v25-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/montserrat/montserrat-v25-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/montserrat/montserrat-v25-latin-500.woff') format('woff'), /* Modern Browsers */
          url('../fonts/montserrat/montserrat-v25-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/montserrat/montserrat-v25-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
   }
   /* montserrat-600 - latin */
   @font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 600;
    font-display: swap;
    src: url('../fonts/montserrat/montserrat-v25-latin-600.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../fonts/montserrat/montserrat-v25-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/montserrat/montserrat-v25-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/montserrat/montserrat-v25-latin-600.woff') format('woff'), /* Modern Browsers */
          url('../fonts/montserrat/montserrat-v25-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/montserrat/montserrat-v25-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
   }
   /* montserrat-700 - latin */
   @font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 700;
    font-display: swap;
    src: url('../fonts/montserrat/montserrat-v25-latin-700.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../fonts/montserrat/montserrat-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/montserrat/montserrat-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/montserrat/montserrat-v25-latin-700.woff') format('woff'), /* Modern Browsers */
          url('../fonts/montserrat/montserrat-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/montserrat/montserrat-v25-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
   }
   /* montserrat-800 - latin */
   @font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 800;
    font-display: swap;
    src: url('../fonts/montserrat/montserrat-v25-latin-800.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../fonts/montserrat/montserrat-v25-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/montserrat/montserrat-v25-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/montserrat/montserrat-v25-latin-800.woff') format('woff'), /* Modern Browsers */
          url('../fonts/montserrat/montserrat-v25-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/montserrat/montserrat-v25-latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
   }
   /* montserrat-900 - latin */
   @font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 900;
    font-display: swap;
    src: url('../fonts/montserrat/montserrat-v25-latin-900.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('../fonts/montserrat/montserrat-v25-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/montserrat/montserrat-v25-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/montserrat/montserrat-v25-latin-900.woff') format('woff'), /* Modern Browsers */
          url('../fonts/montserrat/montserrat-v25-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/montserrat/montserrat-v25-latin-900.svg#Montserrat') format('svg'); /* Legacy iOS */
   }
 
   /* President */
@font-face {
     font-family: 'President';
     src: url('../fonts/president.woff2') format('woff2');
     font-weight: 400;
     font-style: normal;
     font-display: swap;
}