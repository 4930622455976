%ms-heading {
    //ToDo Insert correct font
    font-family: $headings-font-family;
}

.ms-heading-primary {
    @extend %ms-heading;
    color: $primary;
}

.ms-heading-secondary {
    @extend %ms-heading;
    color: $secondary;
}

.ms-heading-white {
    @extend %ms-heading;
    color: white;
}

.ms-ff-secondary {
    font-family: "Montserrat", sans-serif;
}

%ms-subheading {
    font-family: "Montserrat", sans-serif;
    font-size: $font-size-base;
    text-transform: uppercase;
    font-weight: 600 !important;
    letter-spacing: 0.05rem;

    @include media-breakpoint-up(lg) {
        letter-spacing: 0.123rem;
    }
}

.ms-subheading-primary {
    @extend %ms-subheading;
    color: $primary;
}

.ms-subheading-secondary {
    @extend %ms-subheading;
    color: $secondary
}

.ms-subheading-white {
    @extend %ms-subheading;
    color: white;
}


.ms-bg-primary-light {
    background-color: $light;
}

.ms-bg-info {
    background-color: $info;
}


//BUTTONS
%ms-btn {
    @include button-variant($background: $primary,
        $border: $primary,
        $color: white,
        $hover-color: $secondary,
        $hover-border: $secondary,
        $hover-background: $primary );
    @extend %ms-subheading;
}

.ms-btn-primary {
    @extend %ms-btn;
}

.ms-btn-secondary {
    @extend %ms-btn;
    @include button-variant($secondary,
        transparent,
        $color: white);
    @extend %ms-subheading;
}

.ms-btn-white {
    @extend %ms-btn;
    @include button-variant(white,
        transparent,
        $color: $secondary);
    @extend %ms-subheading;
}

.ms-btn-secondary-inverse {
    @extend .ms-btn-secondary;
    color: $secondary;
    background-color: $info;
}

.ms-btn-outline-primary {
    @extend %ms-subheading;
    @include button-outline-variant($color: $secondary,
        $active-background: transparent,
        $active-border: transparent);

    border: solid 3px $frame-primary;

    &:hover {
        border: solid 3px $secondary;
        color: white;
        background: $secondary;
    }
}

.ms-btn-outline-secondary {
    @include button-outline-variant($color: $secondary,
        $active-background: transparent,
        $active-border: transparent);

    border: solid 3px $frame-primary;
}

%sm-btn-icon-right {
    border: 3px solid $primary;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &>svg {
        color: $primary;
        height: 1.2rem;
        width: 1.2rem;
    }
}

.ms-btn-icon-right-secondary {
    @include button-variant($secondary,
        transparent,
        $color: white);
    @extend %ms-subheading;
    display: flex;
    align-items: center;
    padding-right: 3px;
    padding-bottom: 3px;
    padding-top: 3px;

    & .ms-btn-icon-right-secondary-icon {
        @extend %sm-btn-icon-right;
    }
}

.ms-btn-icon-right-primary {
    @include button-variant(white,
        transparent,
        $color: $secondary );
    @extend %ms-subheading;
    display: flex;
    align-items: center;
    padding-right: 3px;
    padding-bottom: 3px;
    padding-top: 3px;

    & .ms-btn-icon-right-primary-icon {
        @extend %sm-btn-icon-right;
    }
}

%sm-btn-icon-left {
    border: 3px solid $primary;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &>svg {
        color: $primary;
        height: 1.2rem;
        width: 1.2rem;
    }
}

.ms-btn-icon-left-primary {
    @include button-variant(white,
        transparent,
        $color: $secondary );
    @extend %ms-subheading;
    display: flex;
    align-items: center;
    padding-left: 3px;
    padding-bottom: 3px;
    padding-top: 3px;

    & .ms-btn-icon-left-primary-icon {
        @extend %sm-btn-icon-left;
    }
}


.ms-btn-icon-left-secondary {
    @include button-variant($secondary,
        transparent,
        $color: white);
    @extend %ms-subheading;
    display: flex;
    align-items: center;
    padding-left: 3px;
    padding-bottom: 3px;
    padding-top: 3px;

    & .ms-btn-icon-left-secondary-icon {
        @extend %sm-btn-icon-left;
    }
}

.ms-btn-multiline {
    line-height: 1rem;
    padding: 0.8rem 1.5rem;

    &>.subline {
        opacity: 0.7;
        font-size: 0.7rem;
    }
}

.ms-btn-icon-right-primary-inverse {
    @extend .ms-btn-icon-right-primary;

    border: solid 3px $primary;

    & .ms-btn-icon-right-primary-icon {
        background-color: $primary;
        border-color: white;

        & > svg {
            color: white;
        }
    }
}



//FRAMING
%ms-image-frame {
    display: inline-block;
    position: relative;

    &:before {
        position: absolute;
        width: 100%;
        border: 3px solid $bg-primary;
        content: '';
    }
}

.ms-image-frame-left {
    @extend %ms-image-frame;

    &:before {
        height: calc(100% + 2 * $frame-offset);
        left: -$frame-offset;
        top: -$frame-offset;

        @include media-breakpoint-up(md) {
            height: calc(100% + 2 * $frame-md-offset);
            left: -$frame-md-offset;
            top: -$frame-md-offset;
        }
    }
}


.ms-image-frame-right {
    @extend %ms-image-frame;

    &:before {
        height: calc(100% + 2 * $frame-offset);
        left: $frame-offset;
        top: -$frame-offset;

        @include media-breakpoint-up(md) {
            height: calc(100% + 2 * $frame-md-offset);
            left: $frame-md-offset;
            top: -$frame-md-offset;
        }
    }
}

.ms-image-frame-top-right {
    @extend %ms-image-frame;

    &:before {
        height: 100%;
        left: $frame-offset;
        top: -$frame-offset;

        @include media-breakpoint-up(md) {
            height: 100%;
            left: $frame-md-offset;
            top: -$frame-md-offset;
        }
    }
}

.ms-image-frame-top-left {
    @extend %ms-image-frame;

    &:before {
        height: 100%;
        left: -$frame-offset;
        top: -$frame-offset;

        @include media-breakpoint-up(md) {
            height: 100%;
            left: -$frame-md-offset;
            top: -$frame-md-offset;
        }
    }
}

.ms-image-frame-inner {
    @extend %ms-image-frame;

    &:before {
        height: calc(100% - 2 * $frame-offset);
        width: calc(100% - 2 * $frame-offset);
        left: $frame-offset;
        top: $frame-offset;

        @include media-breakpoint-up(md) {
            height: calc(100% - 2 * $frame-md-offset);
            width: calc(100% - 2 * $frame-md-offset);
            left: $frame-md-offset;
            top: $frame-md-offset;
        }
    }
}

.ms-frame-container {
    position: relative;
    z-index: $z-index-frame-container;
}


%ms-frame {
    position: absolute;
    width: 100%;
    border: 3px solid $bg-primary;
    z-index: $z-index-frame;
    pointer-events: none;
}

.ms-frame-left {
    @extend %ms-frame;

    margin-left: -$frame-offset;
    margin-top: -$frame-offset;
    height: calc(100% + 2 * $frame-offset);

    @include media-breakpoint-up(md) {
        margin-left: -$frame-md-offset;
        margin-top: -$frame-md-offset;
        height: calc(100% + 2 * $frame-md-offset);
    }
}

.ms-frame-top-left {
    @extend %ms-frame;

    margin-left: -$frame-offset;
    margin-top: -$frame-offset;
    height: 100%;

    @include media-breakpoint-up(md) {
        margin-left: -$frame-md-offset;
        margin-top: -$frame-md-offset;
        height: 100%
    }
}

.ms-frame-top-left.ms-frame-gallery {
    top: 0;
    left: 0;
    margin-left: -.5rem;
    margin-top: -.5rem;

    @include media-breakpoint-up(md) {
        margin-left: -.5rem;
        margin-top: -.5rem;
    }
}



.ms-frame-inner {
    @extend %ms-frame;

    height: calc(100% - 2 * $frame-offset);
    width: calc(100% - 2 * $frame-offset);
    left: $frame-offset;
    top: $frame-offset;

    @include media-breakpoint-up(md) {
        height: calc(100% - 2 * $frame-md-offset);
        width: calc(100% - 2 * $frame-md-offset);
        left: $frame-md-offset;
        top: $frame-md-offset;
    }
}

.ms-frame-inner-sm {
    @extend %ms-frame;

    height: calc(100% - $frame-offset);
    width: calc(100% - $frame-offset);
    left: 0.5 * $frame-offset;
    top: 0.5 * $frame-offset;

    @include media-breakpoint-up(md) {
        height: calc(100% - $frame-md-offset);
        width: calc(100% - $frame-md-offset);
        left: 0.5 * $frame-md-offset;
        top: 0.5 * $frame-md-offset;
    }
}

.ms-frame-top-right {
    @extend %ms-frame;

    height: 100%;
    width: 100%;
    left: $frame-offset;
    top: -$frame-offset;

    @include media-breakpoint-up(md) {
        height: 100%;
        width: 100%;
        left: $frame-md-offset;
        top: -$frame-md-offset;
    }
}


.ms-frame-bottom-right {
    @extend %ms-frame;

    height: 100%;
    width: 100%;
    left: $frame-offset;
    top: $frame-offset;

    @include media-breakpoint-up(md) {
        height: 100%;
        width: 100%;
        left: $frame-md-offset;
        top: $frame-md-offset;
    }
}


// / frames


//ms-bg-dotted
.ms-bg-dotted {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f7f0e8' fill-opacity='0.1' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
}




/* WIDGETS */
// w-sale-floater
.ms-w-sale-floater-sale-amount {
    font-size: 4rem;
    line-height: 4rem;
}

.ms-w-sale-floater-wrapper {
    width: 480px;
    border-radius: 44px;
    right: 1rem;
    bottom: 1rem;
    z-index: $zindex-fixed;
    box-shadow: 0px 12px 20px #C49C6279;
}

// /w-sale-floater


// w-footer
.ms-w-footer-social-icon {
    height: 3rem;
    width: 3rem;
    padding: 0.8rem;
    @extend .mx-1;
}

// /w-footer

// w-timeline
.ms-w-timeline {

    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        width: calc(100% - 2rem);
        background-color: $primary;
        height: 3px;
        top: 50%;
        left: 1rem;
        right: 3rem;
        transform: translateY(-50%);
    }
}

.ms-w-timeline-endbubble {
    position: relative;
    max-height: 4rem;
    width: 4rem;
    border-radius: 50px;
    background-color: $primary;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

@include media-breakpoint-up(md) {
    .ms-w-timeline-endbubble {
        position: absolute;
        height: 3rem;
        width: 3rem;
        border-radius: 25px;
        background-color: $primary;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.ms-w-timeline-dots {
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: .8rem;

    &>div {
        width: 1rem;
        height: 1rem;
        background-color: white;
        border-radius: 25px;
        z-index: 2;
        position: relative;
    }

    &::before {
        content: '';
        position: absolute;
        height: calc(100% + 3rem);
        width: 3px;
        background-color: $primary;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
}

@include media-breakpoint-up(md) {
    .ms-w-timeline-dots {

        padding-top: unset;
        display: block;

        &>div {
            margin-top: unset;
        }

        &::before {
            content: '';
            height: 0px;
            width: opx;
        }
    }
}

@include media-breakpoint-down(sm) {
    .ms-w-timeline-row {
        height: 100%;
    }
}

// /w-timeline

// w-img-text
.ms-w-img-text-text {
    max-width: 41rem;
}

@include media-breakpoint-up(md) {
    .ms-w-img-text-container {
        padding-top: 10.2rem;
        padding-bottom: 15rem;
    }
}

.ms-w-img-text-container {
    padding-top: 3.5rem;
    padding-bottom: 2.3rem;
}

.ms-w-img-text-image {
    object-fit: contain;
    max-width: 100%;
}
// /w-img-text


// ms-nav
.dropdown-toggle::after {
    display: none;
}

@include media-breakpoint-down(sm) {
    .ms-nav-brand {
        height: 50px;
    }
}
// /ms-nav



// w-orderform

.ms-w-orderform-switch-btn {
    @extend .btn-group;

    & > label {
        @extend %ms-subheading;
    }

    & > .btn-check:checked + .btn {
        color: white;
    }

    & > label > span {
        white-space: nowrap;
    }
}
// /w-orderform


// w-contact-section
picture.ms-w-contact-section-image {

    opacity: 90%;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include media-breakpoint-down(sm) {
        max-height: 50%;
    }
}
img.ms-w-contact-section-image {

    opacity: 90%;
    width: 100%;
    height: 100%;
    object-fit: cover;


}

.ms-w-contact-section-image-wrapper {
    @include media-breakpoint-down(sm) {
        max-height: 225px;
    }
}
// /w-contact-section




// w-service

.ms-w-service-svg-wrapper {
    position: relative; 

    /*&::after {
        content: '';
        background-color: $light;
        height: 2rem;
        width: 3rem;
        position: absolute;
    }*/

    & > svg {
        height: 4rem;
        width: 5rem;
        position: relative;
        z-index: 1;
    }
}

// /w-service



// /w-gallery

.ms-w-gallery-product-image {

    object-fit: cover;
    height: 100%;
    width: 100%;

}

.ms-w-gallery-filter-banner {
    background: transparent linear-gradient(180deg, $info 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;

    @include media-breakpoint-up(md) {
        background: $info 0% 0% no-repeat padding-box;
    }
}

.ms-w-gallery-category-filter-button.selected {
    background-color: $info-dark !important;
    color: white !important;
}

.ms-w-gallery-feature-filter-button {
    
    @extend .ms-btn-outline-primary;

    color: $primary;

    &:hover, &.selected, &:active {
        color: white;
        background-color: $primary;
        border-color: $primary;
    }
}
// /w-gallery


// ms-w-3d-editor
.ms-w-ar-3d-editor-material-item {
    @extend .mx-1;

    overflow: hidden;
    border-radius: 50%;
    border: 3px solid $primary;
    height: 3rem;
    width: 3rem;
    background-color: $light;
}


.ms-w-pdp-activate-ar-btn {
    //@extend .mb-1;
    @extend .ms-subheading-secondary;
    display: flex;
    align-items: center;
    border: none;

    & > svg {
        color: $secondary;
        stroke-width: .1rem;
        width: 2rem;
    }
}
// /ms-w-3d-editor


// ms-w-header-banner
.ms-w-header-banner-wrapper {
    //height: 50vh;
    min-height: 50vh;
    max-height: 70vh;
    height: 600px;
    
    @include media-breakpoint-down(sm) {
        min-height: 45vh;
        height: unset;
        max-height: 75vh;
    }
}

.ms-w-header-banner-background-image {
    object-fit: cover;
    //height: 50vh;
    height: 100%;

    @include media-breakpoint-up(md) {
        object-position: center 25%;
    }

/*
    @include media-breakpoint-down(sm) {
        min-height: 45vh;
        height: 50vh;
        max-height: 75vh;
    }*/
}

// /ms-w-header-banner


// w-livecta-speech-bubble
.ms-w-livecta-speech-bubble {
    border-bottom: 3px solid $secondary;
    position: relative;

    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 0px;
        height: 0px;
    }

    &:before {
        right: 105px;
        bottom: -38px;
        border: 19px solid;
        border-color: $secondary $secondary transparent transparent;
    }

    &:after {
        right: 108px;
        bottom: -30px;
        border: 20px solid;
        border-color: $info $info transparent transparent;
    }
}

// w-livecta-speech-bubble


//w-gallery-teaser 
.ms-w-gallery-teaser-image-wrapper {
    grid-template-areas: "Area Area-2 Area-3"
                        "Area-4 Area-5 Area-6";
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    display: grid;
}

// /w-gallery-teaser



//w-text-w-elements
.ms-w-text-w-elements-grid {
    display: grid; 
    grid-template-columns: repeat(2, 1fr); 
    grid-template-rows: repeat(2, 1fr); 
    grid-column-gap: 6px; 
    grid-row-gap: 6px;

    @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(1, 1fr); 
    }
}

// /w-text-w-elements


//w-three-images-image
.ms-w-three-images-image {
    @include media-breakpoint-down(md) {
        max-height: 40vh;
    }
}
// /w-three-images-image


//w-image-center
.ms-w-image-center-image {
    max-height: 50vh;
    height: auto;
    width: auto;
    max-width: 100%;
    object-fit: contain;
}

// /w-image-center



// w-family-hero
.ms-w-hero-text-wrapper {

    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0;
    z-index: 5;

    @include media-breakpoint-up(md) {
        position: relative;
        width: 50%;
    }
}

.ms-w-hero-image-wrapper {
    
    filter: brightness(80%);
    background-color: $primary;
    min-height: 50vh;

    @include media-breakpoint-up(md) {
        min-height: 30vh;
        width: 50%;
        filter: brightness(100%);
    }
}


// /w-family-hero


// w-author
.ms_cd_author_name {
    color: $secondary;
}

.ms_cd_author_wrapper {
    @include media-breakpoint-up(lg) {
        background-color: $info;
        text-align: center;
    }
}

.ms_cd_author_header {
    display: flex;
    align-items: center;

    @include media-breakpoint-up(lg) {
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
    }
}

.ms_cd_author_image {
    width: 50px;
    height: 50px;
    max-width: unset;
}
// /w-author


