
/* COLORS */
//BOOTSTRAP DEFAULT COLORS
$primary: #C49C62;
$secondary: #425368;
//$success: ;
$info: #EFF3F6;
$info-dark: #455266;
//$warning: ;
$danger: #9B4444;
$light: #F7F0E8;
//$dark: ;

$body-bg: white;
$body-color: $secondary;

$link-hover-color: $secondary;
$btn-link-hover-color: $secondary;


//CUSTOM COLORS
$bg-primary: #BC9762;
$frame-primary: #BE9E6A;


/* TYPOGRAPHY */
$font-family-base: 'Vollkorn', Arial, serif;
$headings-font-family: "Altona Sans", sans-serif;
$link-decoration: none;
//$font-family-heading: "Altona Sans", sans-serif;


/* BUTTONS */
$btn-border-radius: 2rem;
$btn-border-radius-sm: 2rem;
$btn-border-radius-lg: 2rem;

$btn-padding-y: .9rem;
$btn-padding-x: 1.5rem;

$btn-padding-y-sm: .7rem;
$btn-padding-x-sm: 1.25rem;

/* BUTTON GROUP */
//$input-group-addon-color: white;


$enable-negative-margins: true;


/* NAVBAR */ 
$navbar-light-color:                $secondary;
//$navbar-light-hover-color:          $info;
$navbar-light-active-color:         $primary;
//$navbar-light-disabled-color:       
//$navbar-light-toggler-icon-bg:      url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="4" stroke="currentColor" height="1rem"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" /></svg>');
$navbar-light-toggler-border-color: none;

$tooltip-opacity: 1;
$tooltip-bg: $secondary;

//@import "~bootstrap/scss/bootstrap";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";


@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions"; //ToDo Remove
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/badge";


@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";


/* FRAME VARIABLES */
$frame-offset: .5rem;
$frame-md-offset: 1rem;

/* EXTEND OVERFLOW UTILITY CLASSES */
@include media-breakpoint-up(md) { 
    .overflow-md-visible {
        overflow: visible !important;
    }
}


$z-index-frame-container:       480;
$z-index-frame:                 500;
$z-index-frame-content:         600;
$z-index-frame-fade:            650;
$z-index-frame-highlight:       655;

.fs-7 {
    font-size: .9rem !important;
}

.fs-8 {
    font-size: .8rem !important;
}

@import "./corporate-design-customizing.scss";
@import "./uiball-loaders.scss";
@import "./splide.min.scss";



body {
    width: 100vw;
    overflow-x: hidden;
}


//Enable tooltip newline with the encoded characters: &#013;&#010;
.tooltip-inner {
    white-space: pre-line !important;
}
