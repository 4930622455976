.splide__container,
.splide__slide {
    box-sizing: border-box;
    position: relative
}

.splide__list {
    backface-visibility: hidden;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important
}

.splide.is-initialized:not(.is-active) .splide__list {
    display: block
}

.splide__pagination {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0;
    pointer-events: none
}

.splide__pagination li {
    display: inline-block;
    line-height: 1;
    list-style-type: none;
    margin: 0;
    pointer-events: auto
}

.splide:not(.is-overflow) .splide__pagination,
.splide__toggle.is-active .splide__toggle__play,
.splide__toggle__pause {
    display: none
}

.splide__progress__bar {
    width: 0
}

.splide {
    position: relative;
    visibility: hidden
}

.splide.is-initialized,
.splide.is-rendered {
    visibility: visible
}

.splide__slide {
    backface-visibility: hidden;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    list-style-type: none !important;
    margin: 0;
    -webkit-tap-highlight-color: transparent
}

.splide__slide img {
    vertical-align: bottom
}

.splide__spinner {
    animation: 1s linear infinite splide-loading;
    border: 2px solid #999;
    border-left-color: transparent;
    border-radius: 50%;
    bottom: 0;
    contain: strict;
    display: inline-block;
    height: 20px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px
}

.splide__sr {
    clip: rect(0 0 0 0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.splide__toggle.is-active .splide__toggle__pause {
    display: inline
}

.splide__track {
    overflow: hidden;
    position: relative;
    z-index: 0
}

.splide__track--draggable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.splide__track--fade>.splide__list>.splide__slide {
    margin: 0 !important;
    opacity: 0;
    z-index: 0
}

.splide__track--fade>.splide__list>.splide__slide.is-active {
    opacity: 1;
    z-index: 1
}

.splide__arrow {
    -ms-flex-align: center;
    align-items: center;
    border: 0;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    background-color: unset;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3rem;
    height: 3rem;
    z-index: 1;
    @extend .border; 
    @extend .border-4;
    @extend .border-primary;
    @extend .bg-white;
}

.splide__arrow svg {
    width: 100%;
    color: $primary;
}

.splide__arrow:hover:not(:disabled)>svg {
    opacity: .9
}

.splide__arrow:disabled>svg {
    opacity: .3
}

.splide__arrow:focus-visible {
    outline: #0bf solid 3px;
    outline-offset: 3px
}

.splide__arrow--prev {
    left: .5rem;
    background: linear-gradient(90deg, #fff 0, rgba(255, 255, 255, 0) 100%)
}

.splide__arrow--next {
    right: .5rem;
    background: linear-gradient(270deg, #fff 0, rgba(255, 255, 255, 0) 100%)
}

.splide__pagination {
    bottom: .5em;
    left: 0;
    padding: 0 1em;
    position: absolute;
    right: 0;
    z-index: 1
}

.splide__pagination__page {
    background: #fff;
    border: 0;
    border-radius: 50%;
    display: inline-block;
    height: 8px;
    margin: 3px;
    opacity: .7;
    padding: 0;
    position: relative;
    transition: transform .2s linear;
    width: 8px
}

.splide__pagination__page.is-active {
    color: #000;
    transform: scale(1.4);
    z-index: 1
}

.splide__pagination__page:hover {
    cursor: pointer;
    opacity: .9
}

.splide__pagination__page:focus-visible {
    outline: #0bf solid 3px;
    outline-offset: 3px
}

.splide.is-focus-in .splide__pagination__page:focus {
    outline: #0bf solid 3px;
    outline-offset: 3px
}

.splide__progress__bar {
    background: #ccc;
    height: 3px
}

.splide__slide:focus {
    outline: 0
}

@supports(outline-offset:-3px) {
    .splide__slide:focus-visible {
        outline: #0bf solid 3px;
        outline-offset: -3px
    }

    .splide.is-focus-in .splide__slide:focus {
        outline: #0bf solid 3px;
        outline-offset: -3px
    }
}

@media screen and (-ms-high-contrast:none) {
    .splide__slide:focus-visible {
        border: 3px solid #0bf
    }

    .splide.is-focus-in .splide__slide:focus {
        border: 3px solid #0bf
    }

    .splide.is-focus-in .splide__track>.splide__list>.splide__slide:focus {
        border-color: #0bf
    }
}

.splide__toggle {
    cursor: pointer
}

.splide__toggle:focus-visible {
    outline: #0bf solid 3px;
    outline-offset: 3px
}

.splide.is-focus-in .splide__toggle:focus {
    outline: #0bf solid 3px;
    outline-offset: 3px
}

.splide__track--nav>.splide__list>.splide__slide {
    border: 3px solid transparent;
    cursor: pointer
}

.splide__track--nav>.splide__list>.splide__slide.is-active {
    border: 3px solid #000
}


/* Customization */
.splide__slide {
    padding-left: 10px;    
    padding-right: 10px;    
}

.splide__slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}